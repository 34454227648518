import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import Helpers from "@/utils/helpers";

export type Getters = {
}

export const getters: GetterTree<State, RootState> & Getters = {
};
